import { SystemPageKey } from 'page/SystemPageKey'
import { compile, match } from 'path-to-regexp'
import { ParsedUrlQuery } from 'querystring'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { TSystemPageRoute } from '../TRoute'
import { queryParamsToString } from '../urls/queryParamsToString'
import { TSystemPageRouteMatchResult } from './TSystemPageRouteMatchResult'

export const systemPageRoute = <
  PathParams extends {} = {},
  QueryParams extends ParsedUrlQuery = {},
>(
  systemPageKey: SystemPageKey,
  routePath: string,
  queryParamsHandling?: 'queryparams-required'
): TSystemPageRoute<{ pathParams: PathParams; queryParams: QueryParams }> => {
  const matcher = match<PathParams>(routePath, {
    sensitive: true,
  })
  const compiler = compile(routePath, {
    sensitive: true,
  })
  return {
    systemPageKey,
    exec: (url: string, queryParams: ParsedUrlQuery) => {
      const matcherResult = matcher(url)
      if (!matcherResult) {
        return false
      }

      if (
        queryParamsHandling === 'queryparams-required' &&
        Object.keys(queryParams).length === 0
      ) {
        return false
      }

      return {
        type: PageTypeKey.SystemPage,
        systemPageKey,
        url,
        pathParams: matcherResult.params,
      } as TSystemPageRouteMatchResult<PathParams>
    },
    compile: ({
      pathParams,
      queryParams,
    }: {
      pathParams?: PathParams
      queryParams?: QueryParams
    }) => {
      const url = compiler(pathParams)
      const queryString = queryParams && queryParamsToString(queryParams)

      return `${url}${queryString ? `?${queryString}` : ''}`
    },
  }
}
