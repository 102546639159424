import { match } from 'path-to-regexp'
import { ParsedUrlQuery } from 'querystring'
import { TRedirect } from './TRedirect'
import { TRedirectGenerator } from './TRedirectGenerator'

export const redirect = <
  PathParams extends {} = {},
  QueryParms extends ParsedUrlQuery = {},
>(
  routePath: string,
  target: string | TRedirectGenerator<PathParams, QueryParms>
): TRedirect<QueryParms> => {
  const matcher = match<PathParams>(routePath, {
    sensitive: true,
  })

  return {
    exec: async ({ url, queryParams, locale, ip }) => {
      const matcherResult = matcher(url)
      if (!matcherResult) {
        return false
      }

      const computedTarget =
        typeof target === 'string'
          ? target
          : await target({
              pathParams: matcherResult.params,
              queryParams,
              locale,
              ip,
            })

      if (!computedTarget) {
        return false
      }

      return {
        url,
        target: computedTarget,
      }
    },
  }
}
