import { getSSRMachineTypeName } from '@internal/i18n/getSSRMachineTypeName'
import { isValidMachineType } from '@website-shared-library/machine/isValidMachineType'
import { SystemPageKey } from 'page/SystemPageKey'
import { TSearchResultsRouteParams } from '../routingTable'
import { urlFor } from '../urls/urlFor'
import { urlForSystemPageWithoutParams } from '../urls/urlForSystemPageWithoutParams'
import { TRedirectGenerator } from './TRedirectGenerator'

export const getRedirectForSearchResultsPage: TRedirectGenerator = async ({
  locale,
  queryParams,
}) => {
  if (Object.keys(queryParams).length === 0) {
    return urlForSystemPageWithoutParams(locale, SystemPageKey.StartPage)
  }

  const searchParams =
    queryParams as TSearchResultsRouteParams['queryParams'] & {
      machineType?: string
    }
  if (
    searchParams.machineType &&
    isValidMachineType(searchParams.machineType)
  ) {
    const { machineType, ...params } = searchParams
    return urlFor(locale, {
      key: SystemPageKey.SearchResults,
      machineTypeName: getSSRMachineTypeName(
        locale,
        searchParams.machineType,
        'plural'
      ),
      queryParams: params,
    })
  }

  return false
}
