import { ParsedUrlQuery } from 'querystring'

export const queryParamsToString = (queryParams: ParsedUrlQuery) => {
  const urlSearchParams = new URLSearchParams()

  Object.keys(queryParams).forEach((key) => {
    const value = queryParams[key]
    if (Array.isArray(value)) {
      value.forEach((v) => {
        if (value !== undefined) {
          urlSearchParams.append(key, `${v}`)
        }
      })
    } else if (value !== undefined) {
      urlSearchParams.append(key, `${value}`)
    }
  })

  return urlSearchParams.toString()
}
