import { SystemPageKey } from 'page/SystemPageKey'
import { urlFor } from '../urls/urlFor'
import { TRedirectGenerator } from './TRedirectGenerator'

export const getRedirectForMachineComparePage: TRedirectGenerator<{
  machineGroupId0?: string
  machineGroupId1?: string
  machineGroupId2?: string
}> = async ({ pathParams, locale, queryParams }) =>
  urlFor(locale, {
    key: SystemPageKey.CompareMachines,
    pathParams: {
      machineGroupId0: pathParams.machineGroupId0?.toLowerCase() ?? undefined,
      machineGroupId1: pathParams.machineGroupId1?.toLowerCase() ?? undefined,
      machineGroupId2: pathParams.machineGroupId2?.toLowerCase() ?? undefined,
    },
    queryParams,
  })
