import { compile, match } from 'path-to-regexp'
import { PageTypeKey } from 'utils/page/PageTypeKey'
import { TMachineCategoryPageRouteParams } from '../routingTable'
import { TRoute } from '../TRoute'
import { queryParamsToString } from '../urls/queryParamsToString'

export const machineCategoryPageRoute = (
  routePath: string
): TRoute<TMachineCategoryPageRouteParams> => {
  const matcher = match<TMachineCategoryPageRouteParams['pathParams']>(
    routePath,
    {
      sensitive: true,
    }
  )
  const compiler = compile(routePath, {
    sensitive: true,
  })
  return {
    exec: (url: string) => {
      const matcherResult = matcher(url)
      if (!matcherResult) {
        return false
      }

      return {
        type: PageTypeKey.MachineCategoryPage,
        url,
        params: {
          pathParams: matcherResult.params,
          queryParams: {},
        },
      }
    },
    compile: (params: TMachineCategoryPageRouteParams) => {
      const pathParams: TMachineCategoryPageRouteParams['pathParams'] = {
        machineTypeSlug: params.pathParams.machineTypeSlug,
      }
      if (params.pathParams.brandSlug) {
        pathParams.brandSlug = params.pathParams.brandSlug
      }
      if (params.pathParams.modelSlug) {
        pathParams.modelSlug = params.pathParams.modelSlug
      }
      const url = compiler(pathParams)
      const queryString =
        params.queryParams && queryParamsToString(params.queryParams)

      return `${url}${queryString ? `?${queryString}` : ''}`
    },
  }
}
