export class MachineNotFoundError extends Error {
  constructor(machineGroupId: string) {
    super(`Machine ${machineGroupId} not found`)
  }
}

export class FinalMachineMergeResultNotReachedError extends Error {
  constructor(machineGroupId: string) {
    super(`Final merge result of ${machineGroupId} not reached`)
  }
}
