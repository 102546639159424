import { SearchResultsSortKey } from '@internal/blocks/SearchResultsBlock/SearchResultsSortKey'
import { Locale, LocaleEnum } from '@website-shared-library/machine/i18n/Locale'
import { TLocale } from '@website-shared-library/machine/i18n/TLocale'
import { SystemPageKey } from 'page/SystemPageKey'
import { TRoute } from './TRoute'
import { TRouteParams } from './TRouteParams'
import { machineCategoryPageRoute } from './machineCategoryPages/machineCategoryPageRoute'
import { TRedirect } from './redirects/TRedirect'
import { getRedirectForMachine } from './redirects/getRedirectForMachine'
import { getRedirectForMachineComparePage } from './redirects/getRedirectForMachineComparePage'
import { getRedirectForSearchResultsPage } from './redirects/getRedirectForSearchResultsPage'
import { redirect } from './redirects/redirect'
import { systemPageRoute } from './systemPages/systemPageRoute'

export type TRoutingTableEntry = Record<TLocale, TRoute<any>>

export type TSearchResultsRouteParams = TRouteParams<
  {
    machineTypeSlug?: string
  },
  {
    headerType?: string
    baleChamber?: string
    machineSubType?: string
    brand?: string[]
    model?: string
    baseModel?: string
    amount?: string
    location?: string[]
    feature?: string[]
    sort?: SearchResultsSortKey
  }
>

export type TMachineDetailsRouteParams = TRouteParams<
  {
    machineType: string
    brand: string
    model: string
    machineGroupId: string
  },
  {}
>
export type TCompareMachinesRouteParams = TRouteParams<
  {
    machineGroupId0?: string
    machineGroupId1?: string
    machineGroupId2?: string
  },
  {}
>

export type TMachineCategoryPageRouteParams = TRouteParams<
  {
    machineTypeSlug: string
    brandSlug?: string
    modelSlug?: string
    brand?: string
    model?: string
  },
  {}
>

const genericCompareRoutePrefix = 'compare'
const compareRoutePrefix: Record<TLocale, string> = {
  [Locale.en]: genericCompareRoutePrefix,
  [Locale.de]: 'vergleichen',
  [Locale.fr]: 'comparer',
  [Locale.el]: 'singrisi',
  [Locale.it]: 'confronta',
  [Locale.pl]: 'porownaj',
  [Locale.ro]: 'comparare',
  [Locale.ru]: 'sravnit',
  [Locale.hu]: 'osszehasonlitas',
  [Locale.es]: 'comparar',
  [Locale.bg]: 'sravnjavane',
  [Locale.sl]: 'primerjaj',
  [Locale.sr]: 'uporedite',
  [Locale.da]: 'ammenlign',
  [Locale.cs]: 'porovnat',
  [Locale.lt]: 'palyginti',
}

export const usedFarmMachineryRoutePrefix: Record<TLocale, string> = {
  [Locale.de]: 'gebrauchte-landmaschinen',
  [Locale.en]: 'used-farm-machinery',
  [Locale.fr]: 'machines-agricoles-doccasion',
  [Locale.el]: 'metaxeirismena-georgika-mixanimata',
  [Locale.it]: 'macchine-agricole-usate',
  [Locale.pl]: 'uzywane-maszyny-rolnicze',
  [Locale.ro]: 'utilaje-agricole-second',
  [Locale.ru]: 'bu-technika',
  [Locale.hu]: 'hasznalt-mezogazdasagi-gepek',
  [Locale.es]: 'maquinaria-agricola-usada',
  [Locale.bg]: 'izpolzvani-selskostopanski-mashini',
  [Locale.sl]: 'rabljena-kmetijska-mehanizacija',
  [Locale.sr]: 'polovne-poljoprivredne-masine',
  [Locale.da]: 'brugte-landbrugsmaskiner',
  [Locale.cs]: 'pouzite-zemedelske-stroje',
  [Locale.lt]: 'naudota-zemes-ukio-technika',
}

const shortPdpRoute = '/:locale/:machineGroupId([a-zA-Z0-9]{7})/'

const locales = Object.values(LocaleEnum)
export const routingTable: {
  systemPageRoutes: Record<SystemPageKey, TRoutingTableEntry>
  machineCategoryPageRoutes: TRoutingTableEntry
  searchResultsPage: TRedirect[]
  shortPdp: TRedirect
  redirects: TRedirect[]
  machineRoutes: {
    nonStrictPdp: TRoutingTableEntry
    short: string
  }
} = {
  systemPageRoutes: {
    [SystemPageKey.StartPage]: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute(SystemPageKey.StartPage, `/${locale}/`),
      }),
      {} as TRoutingTableEntry
    ),

    // this needs to be accessible, so we can get a preview of the cms content
    // and don't have to wait for another deployment / server side generation of the 404 page
    // hitting this url will return a HTTP 200 (not a 404)
    [SystemPageKey.NotFound]: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute(SystemPageKey.NotFound, `/${locale}/_404/`),
      }),
      {} as TRoutingTableEntry
    ),

    // this needs to be accessible, so we can get a preview of the cms content
    // and don't have to wait for another deployment / server side generation of the 500 page
    // hitting this url will return a HTTP 200 (not a 500)
    [SystemPageKey.InternalServerError]: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute(
          SystemPageKey.InternalServerError,
          `/${locale}/_500/`
        ),
      }),
      {} as TRoutingTableEntry
    ),

    [SystemPageKey.PDP]: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute<TMachineDetailsRouteParams>(
          SystemPageKey.PDP,
          `/${locale}/${usedFarmMachineryRoutePrefix[locale]}/:machineType/:brand/:model/:machineGroupId([a-z0-9]{7})/`
        ),
      }),
      {} as TRoutingTableEntry
    ),

    [SystemPageKey.SearchResults]: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute<TSearchResultsRouteParams>(
          SystemPageKey.SearchResults,
          `/${locale}/${usedFarmMachineryRoutePrefix[locale]}/:machineTypeSlug/`
        ),
      }),
      {} as TRoutingTableEntry
    ),

    [SystemPageKey.CompareMachines]: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute<TCompareMachinesRouteParams>(
          SystemPageKey.CompareMachines,
          `/${locale}/${compareRoutePrefix[locale]}/:machineGroupId0([a-z0-9]{7})?/:machineGroupId1([a-z0-9]{7})?/:machineGroupId2([a-z0-9]{7})?/`
        ),
      }),
      {} as TRoutingTableEntry
    ),
  },

  machineRoutes: {
    nonStrictPdp: locales.reduce<TRoutingTableEntry>(
      (map, locale) => ({
        ...map,
        [locale]: systemPageRoute<TMachineDetailsRouteParams>(
          SystemPageKey.PDP,
          `/${locale}/${usedFarmMachineryRoutePrefix[locale]}/:machineType/:brand/:model/:machineGroupId([a-zA-Z0-9]{7})/`
        ),
      }),
      {} as TRoutingTableEntry
    ),
    short: shortPdpRoute,
  },

  machineCategoryPageRoutes: locales.reduce<TRoutingTableEntry>(
    (map, locale) => ({
      ...map,
      [locale]: machineCategoryPageRoute(
        `/${locale}/${usedFarmMachineryRoutePrefix[locale]}/:machineTypeSlug/:brandSlug?/:modelSlug?/`
      ),
    }),
    {} as TRoutingTableEntry
  ),

  searchResultsPage: locales.map((locale) =>
    redirect(
      `/${locale}/${usedFarmMachineryRoutePrefix[locale]}/`,
      getRedirectForSearchResultsPage
    )
  ),

  shortPdp: redirect<{ machineGroupId: string }>(
    shortPdpRoute,
    getRedirectForMachine
  ),

  redirects: [
    // redirect non pure lowercase machineGroupId
    ...locales.map((locale) =>
      redirect<{ machineGroupId: string }>(
        `/${locale}/${usedFarmMachineryRoutePrefix[locale]}/:machineType/:brand/:model/:machineGroupId([a-zA-Z0-9]{7})/`,
        getRedirectForMachine
      )
    ),

    // machine compare page
    ...locales.map((locale) =>
      redirect<{
        machineGroupId0?: string
        machineGroupId1?: string
        machineGroupId2?: string
      }>(
        `/${locale}/${genericCompareRoutePrefix}/:machineGroupId0([a-zA-Z0-9]{7})?/:machineGroupId1([a-zA-Z0-9]{7})?/:machineGroupId2([a-zA-Z0-9]{7})?/`,
        getRedirectForMachineComparePage
      )
    ),
  ],
}
